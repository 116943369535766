<template>
  <section class="project--detail-header">
    <div class="d-flex justify-space-between align-items-start">
      <h1 class="title--text" v-if="unitType">
        <!--        {{ unitType.name }}-->
        {{ unitType.title }}
      </h1>
      <h1 class="title--text" v-else>
        <!--        <span v-if="project.listing_type === listingTypeEnum.SECONDARY">{{ project.title }}</span>-->
        <!--        <span v-else>{{ project.project_name }}</span>-->
        <span>{{ project.title }}</span>
        <listing-membership-expired
          class="ml-2"
          :isMembershipExpired="isMembershipExpired"
        ></listing-membership-expired>
      </h1>
    </div>
    <div class="project--location" v-if="location">
      <img src="@/assets/img/icons/pin-1.svg" alt="Location Pin" />
      <h2>
        {{ location }}
      </h2>
    </div>
    <div
      class="listing--number"
      style="position: relative"
      id="copyToClipboardListingNumber"
      @click="copyToClipboard(project.listing_number)"
      v-if="project.listing_number"
    >
      <div>
        {{ `${$t('general.listingNumber')}: ${project.listing_number}` }}
      </div>
      <div>
        <v-icon>mdi-content-copy</v-icon>
      </div>
      <popover v-show="copyListingNumberSucceeded"></popover>
    </div>
    <div class="price--wrapper">
      <span>{{ price }}</span>
    </div>
    <div class="label--wrapper mt-2">
      <!--      <div class="label label&#45;&#45;normal">-->
      <!--        <v-icon class="mr-2">mdi-eye-outline</v-icon>-->
      <!--        <span>{{ $t('general.view') }} {{ project.view }} {{ $t('general.times') }}</span>-->
      <!--      </div>-->
      <div class="label label--normal">
        <span>{{ project.property_type }}</span>
      </div>
      <!--      <div class="label label&#45;&#45;normal" v-show="project.subsidized">-->
      <!--        <span>{{ $t('general.subsidized') }}</span>-->
      <!--      </div>-->
    </div>
  </section>
</template>

<script>
import { defineAsyncComponent } from 'vue';
const Popover = defineAsyncComponent(() => import('@/components/utils/popover.vue'));
const ListingMembershipExpired = defineAsyncComponent(() =>
  import('@/components/utils/listing-membership-expired.vue'),
);
import { mapState } from 'vuex';

export default {
  name: 'project-detail-header',
  props: ['project', 'unitType'],
  components: {
    Popover,
    ListingMembershipExpired,
  },
  data: () => ({
    copyListingNumberSucceeded: false,
  }),
  computed: {
    ...mapState({
      listingTypeEnum: (state) => state.v2.masters.listingTypeEnum,
    }),
    isMembershipExpired() {
      if (this.project && this.project.owner && this.project.status === 'ACTIVE') {
        return this.project.owner.active_membership_type_id === null;
      }
      return false;
    },
    price() {
      let price = this.$t('general.price') + ' ';
      if (this.unitType) {
        if (this.unitType.min_price_num === this.unitType.max_price_num) {
          price += this.$n(this.unitType.min_price_num, 'currency', 'id-ID');
        } else {
          price += this.$n(this.unitType.min_price_num, 'currency', 'id-ID');
          if (this.unitType.min_price_num && this.unitType.max_price_num) price += ' - ';
          price += this.$n(this.unitType.max_price_num, 'currency', 'id-ID');
        }
      } else {
        if (this.project.listing_type === this.listingTypeEnum.SECONDARY) {
          price += this.$n(this.project.price, 'currency', 'id-ID');
        } else {
          if (this.project.min_price_num === this.project.max_price_num) {
            price += this.$n(this.project.min_price_num, 'currency', 'id-ID');
          } else {
            price += this.$n(this.project.min_price_num, 'currency', 'id-ID');
            if (this.project.min_price_num && this.project.max_price_num) price += ' - ';
            price += this.$n(this.project.max_price_num, 'currency', 'id-ID');
          }
        }
      }
      return price;
    },
    location() {
      let location = null;
      if (this.project.listing_type === this.listingTypeEnum.SECONDARY) {
        if (this.project.area || this.project.city) {
          location = '';
          if (this.project.area) location += this.project.area;
          if (this.project.city) {
            if (location !== '') location += ', ';
            location += this.project.city;
          }
        }
      } else {
        if (this.project.village || this.project.district || this.project.city) {
          location = '';
          if (this.project.village) location += this.project.village;
          if (this.project.district) {
            if (location !== '') location += ', ';
            location += this.project.district;
          }
          if (this.project.city) {
            if (location !== '') location += ', ';
            location += this.project.city;
          }
        }
      }
      return location;
    },
  },
  methods: {
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.handleCopyListingNumberStatus(true);
    },
    handleCopyListingNumberStatus(status) {
      this.copyListingNumberSucceeded = status;
      let self = this;
      setTimeout(() => {
        self.copyListingNumberSucceeded = false;
      }, 2000);
    },
  },
};
</script>
