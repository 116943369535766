var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"project--detail-banner"},[_c('client-only',[(_vm.normalGalleries && _vm.normalGalleries.length > 0)?_c('vue-gallery-slideshow',{attrs:{"images":_vm.normalGalleries,"index":_vm.imageIndex},on:{"close":function($event){return _vm.clickImage(null)}}}):_vm._e(),_c('div',[(_vm.normalGalleries && _vm.normalGalleries.length > 0)?_c('v-slide-group',{staticClass:"carousel--wrapper carousel--desktop"},[_c('v-slide-item',{key:`gallery-0`,ref:"gallery",staticClass:"carousel--slide wrapper--gallery-grid first--image"},[_c('div',[(!_vm.project.pano_embed_url)?_c('div',{staticClass:"img--gallery mb-0",style:(`background-image: url('${_vm.firstGallery}'), url('${_vm.getFallbackImage(
                _vm.firstGallery,
              )}');`),on:{"click":function($event){return _vm.clickImage(0)}}}):_c('div',{staticClass:"img--gallery mb-0"},[(_vm.validUrl(_vm.project.pano_embed_url))?_c('iframe',{staticClass:"pano--gallery",attrs:{"src":_vm.project.pano_embed_url,"allowfullscreen":"allowfullscreen","loading":"lazy"}}):_vm._e()])])]),_vm._l((_vm.galleries),function(gallery,index){return _c('v-slide-item',{key:`gallery-${index + 1}`,ref:"gallery",refInFor:true,staticClass:"carousel--slide wrapper--gallery-grid"},[_c('div',[_c('div',{staticClass:"img--gallery",class:{ 'img--blank': !gallery[0].item },style:(`background-image: url('${
                _vm.forceBlankHDImage(gallery[0].item)
                  ? _vm.forceBlankHDImage(gallery[0].item)
                  : '/img/blank.png'
              }'), url('${
                _vm.forceBlankHDImage(gallery[0].item)
                  ? _vm.getBlankFallbackImage(_vm.forceBlankHDImage(gallery[0].item))
                  : '/img/blank.png'
              }');`),on:{"click":function($event){_vm.clickImage(gallery[0].index + (!_vm.project.pano_embed_url ? 1 : 0))}}}),_c('div',{staticClass:"img--gallery mb-0",class:{ 'img--blank': !gallery[1].item },style:(`background-image: url('${
                _vm.forceBlankHDImage(gallery[1].item)
                  ? _vm.forceBlankHDImage(gallery[1].item)
                  : '/img/blank.png'
              }'), url('${
                _vm.forceBlankHDImage(gallery[1].item)
                  ? _vm.getBlankFallbackImage(_vm.forceBlankHDImage(gallery[1].item))
                  : '/img/blank.png'
              }');`),on:{"click":function($event){_vm.clickImage(gallery[1].index + (!_vm.project.pano_embed_url ? 1 : 0))}}})])])})],2):_vm._e()],1),(_vm.normalGalleries && _vm.normalGalleries.length > 0)?_c('v-carousel',{staticClass:"carousel--wrapper carousel--mobile",attrs:{"hide-delimiters":true}},_vm._l((_vm.normalGalleries),function(gallery,index){return _c('v-carousel-item',{key:`gallery-${index}`,ref:"gallery",refInFor:true,staticClass:"carousel--slide wrapper--gallery-grid"},[_c('div',{staticClass:"img--gallery mb-0",style:(`background-image: url('${_vm.forceBlankHDImage(
            gallery,
          )}'), url('${_vm.getBlankFallbackImage(_vm.forceBlankHDImage(gallery))}');`),on:{"click":function($event){return _vm.clickImage(index)}}})])}),1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }