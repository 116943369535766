<template>
  <div>
    <v-slide-group show-arrows class="carousel--wrapper project--slick">
      <v-slide-item v-for="(content, index) in contents" :key="index" class="carousel--slide">
        <div>
          <!--      <card-project-partner-->
          <!--        v-if="category === 'projectpartner'"-->
          <!--        :projectPartner="content"-->
          <!--        :allowMultiImage="false"-->
          <!--      />-->
          <!--      <card-project :premier="premier" v-else :project="content" :allowMultiImage="false" />-->
          <card-listing
            :key="content.uuid"
            :premier="premier"
            :listing="content"
            :allowMultiImage="false"
            v-if="type === listingTypeEnum.SECONDARY"
          />
          <card-project
            :key="content.uuid"
            :premier="premier"
            :project="content"
            :allowMultiImage="false"
            v-else
          />
        </div>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import { mapState } from 'vuex';

const CardProject = () => import('@/components/utils/card-project');
const CardListing = () => import('@/components/utils/card-listing');

export default {
  components: {
    CardProject,
    CardListing,
  },
  props: {
    contents: {
      default: [],
      require: true,
    },
    category: {
      default: null,
    },
    premier: {
      default: false,
    },
    type: {
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    ...mapState({
      listingTypeEnum: (state) => state.v2.masters.listingTypeEnum,
    }),
  },
  mounted() {},
  methods: {},
};
</script>
